<template>
  <v-container fill-height style="max-width: 1200px" class="pt-5">
    <page-navigation-bar class="" />
    <v-row>
      <v-col cols="12">
        <v-img
          class=""
          height="120"
          contain
          src="https://www.kakaocorp.com/page/img_error.png"
        />
        <v-card class="text-center transparent" flat>
          <v-card-title
            :class="$vuetify.breakpoint.smAndDown ? 'headline' : 'display-1'"
            class="justify-center font-weight-bold"
          >
            There is no page.<br />you are looking for.
          </v-card-title>
          <v-card-subtitle class="ma-2 body-2">
            Invalid access or the requested page could not be found.<br />
            Please double check that the address on the page you entered is
            correct.
          </v-card-subtitle>
          <v-card-actions class="justify-center">
            <v-btn
              class="font-weight-bold body-1 pa-4"
              depressed
              rounded
              x-large
              color="surface"
              >Go Home</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageNavigationBar from "../components/Page/NavigationBar/PageNavigationBar.vue";
import setMeta from "@/utils/setMeta";
export default {
  components: {
    PageNavigationBar,
  },
  mounted() {
    setMeta({ title: "404", description: "Make Imagination True." });
  },
};
</script>

<style></style>
